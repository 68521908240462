let url;
let socketUrl;
let base_url;
let storage_path;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  url = "http://localhost:8000/api";
  socketUrl = "http://localhost:8000/api";
  base_url = "http://localhost:8000";
  storage_path = "http://localhost:8000/storage/images/item-img/";
} else {
  
  // url = "http://192.168.139.201:85/api";
  // socketUrl = "http://192.168.139.201:85/api";
  // base_url = "http://192.168.139.201:85";
  // storage_path = "http://192.168.139.201:85/storage/images/item-img/";
// 
// 
  url = "https://api-trial2-ekanban.sankei-dharma.co.id/api";
  socketUrl = "https://api-trial2-ekanban.sankei-dharma.co.id/api";
  base_url = "https://api-trial2-ekanban.sankei-dharma.co.id";
  storage_path = "https://api-trial2-ekanban.sankei-dharma.co.id/storage/images/item-img/";
  // url = "https://api-trial2-ekanban.sankei-dharma.co.id/api";
// 
  //url = "https://api-dev-ekanban.sankei-dharma.co.id/api";
  //socketUrl = "https://api-dev-ekanban.sankei-dharma.co.id/api";
  //base_url = "https://api-dev-ekanban.sankei-dharma.co.id";
  //storage_path = "https://api-dev-ekanban.sankei-dharma.co.id/storage/images/item-img/";
  // url = "https://api.ekanban.sankei-dharma.co.id/api";
}



export { url, socketUrl, base_url, storage_path };
